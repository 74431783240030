import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../service/locations.service';
import { LocationList } from './locations-edit.model';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
/* eslint-disable */

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {
  // @Input() location: any;
  @Output() saveLocation = new EventEmitter<any>();
  locationInfo: any;
  locationDetail: LocationList;
  drawStats: DrawStatsService;


  constructor(private route: ActivatedRoute, private locationsService: LocationsService) 
  { 
    this.locationInfo = this.getLocationEditDefault() 
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const locationId = params.codigoTienda;
      console.log(params.codigoTienda, 'esto el id de localizacion');
      console.log(params, 'esto es el objeto params');

      this.locationsService.getLocationById(locationId)
        .subscribe(response => {
          // this.location = response;
          this.fillLocationEditData(response);
          // console.log(this.locationInfo, 'QUIERO VER ALGOOOOOOOO');
        });
    });
  }

  //función para rellenar la petición que hagamos con los datos que queremos
  public fillLocationEditData(dataPetiton: any) {
    console.log(dataPetiton, 'esto es la peticion de datos');
    this.locationInfo.codigoTienda = dataPetiton.codigo_tienda;
    this.locationInfo.nombre = dataPetiton.nombre;
    this.locationInfo.direccion = dataPetiton.direccion;
    this.locationInfo.provincia = dataPetiton.provincia;
    this.locationInfo.localidad = dataPetiton.localidad;
  }

  //funcion para guardar los cambios
  public onSave() {
    // lógica para guardar los cambios en el servicio
    this.locationsService.updateLocation(this.locationInfo)
      .subscribe(response => {
        // Manejar la respuesta si es necesario
        this.locationInfo = response;  // Actualiza la propiedad location con la respuesta del servicio
      }, error => {
        console.error('Error al cargar los detalles de la ubicación:', error);
      });
  }

  public getLocationEditDefault() {
    return {
      locationInfo:
      {
        codigo_tienda: 'sin datos',
        nombre: 'sin datos',
        direccion: 'sin datos',
        localidad: 'sin datos',
        provincia: 'sin datos',
      }
    }
  }
}
