import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { AccionesService } from 'src/app/service/acciones.service';
import { FilterService } from 'src/app/service/filter.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { Acciones } from './acciones.model';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';
/* eslint-disable */

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.scss']
})
export class AccionesComponent implements OnInit {
  canvas: any;
  newCanvas: any;
  chartUpdate: any;
  divCanvas: any;
  ctx: any;
  stats: Acciones;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  public customBrandSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customStickerSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public accionesService: AccionesService,
    private drawStatsService: DrawStatsService,
    public filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
      this.drawStats = new DrawStatsService();
      this.stats = this.getStatsDefault();
  }
  ngOnInit(): void {
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      this.drawStats.addSpinner();
      const idBrand = this.filter.getBrandCurrentValue();
      const idSticker = this.filter.getStickerCurrentValue();
      if (date!=="0" && idSticker ==='0' && idBrand ==='0'){
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idBrand,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillAcciones(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      if(date!=="0" && idBrand !== '0'){
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idBrand,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillAcciones(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      if(date!=="0" && idSticker !== '0'){
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idSticker,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillAcciones(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    });
    this.customBrandSubscription = this.filter.customBrand.subscribe(brandId => {
      const date: string = this.filter.getDateCurrentValue();
      if (brandId !=='0'){
      this.drawStats.addSpinner();
      const newDate = new Date(date);
      this.filter.changeType('marca');
      let lang = this.filter.getLangCurrentValue();
      this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),brandId,"marca").subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }else{
          this.fillAcciones(dataStats);
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }
      });
      }
      if (brandId ==="0" && date !== "0"){
        this.drawStats.addSpinner();
        let date:string = this.filter.getDateCurrentValue();
        let newDate = new Date(date);
          let type = this.filter.getTypeCurrentValue();
          let lang = this.filter.getLangCurrentValue();
          this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),brandId,type).subscribe((dataStats) => {
            if(dataStats.data === null){
              this.stats = this.getStatsDefault();
              this.createChart(lang);
              this.drawStats.removeSpinner();
            }else{
              this.fillAcciones(dataStats);
              this.createChart(lang);
              this.drawStats.removeSpinner();
            }
          });
      }
    });
    // this.customStickerSubscription = this.filter.customSticker.subscribe(stickerId => {
    //   if (stickerId!=='0'){
    //   this.drawStats.addSpinner();
    //   const date: string = this.filter.getDateCurrentValue();
    //   const newDate = new Date(date);
    //   this.filter.changeType('sticker');
    //   let lang = this.filter.getLangCurrentValue();
    //   this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),stickerId,"sticker").subscribe((dataStats) => {
    //     if(dataStats.data === null){
    //       this.stats = this.getStatsDefault();
    //       this.createChart(lang);
    //       this.drawStats.removeSpinner();
    //     }else{
    //       this.fillAcciones(dataStats);
    //       this.createChart(lang);
    //       this.drawStats.removeSpinner();
    //     }
    //   });
    //   }
    // });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{
      this.createChart(lang);
    });
  }
  ngOnDestroy(): void{
    this.customBrandSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    // this.customStickerSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }
    createChart(lang:any){
      this.canvas = document.getElementById('myChart');
      if(this.canvas){
        this.canvas.remove();
      }
      this.newCanvas = document.createElement("canvas");
      this.newCanvas.setAttribute("id", "myChart");
      this.newCanvas.setAttribute("width", 620);
      this.newCanvas.setAttribute("height", 400);
      this.newCanvas.setAttribute("class", "grafic");
      this.divCanvas = document.getElementById("canvas3");
      this.divCanvas.appendChild(this.newCanvas);
      this.canvas = this.newCanvas;

    this.ctx = this.canvas.getContext('2d');
    let chartdata = this.createDataChart(this.stats, lang);

    new Chart(this.ctx, {
      type: 'bar',
      data: chartdata,
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
   });

    }

     createDataChart(stats:any, lang: any){
      let web  = [];
      let gps = [];
      let calls =[];
      let month: any =[];
      let labelCalls = this.langMonthService.langCalls(lang);
      for (let i in stats.historical){
        web.push(stats.historical[i].web);
        gps.push(stats.historical[i].gps);
        calls.push(stats.historical[i].calls);
        month.push(this.langMonthService.langMonth(stats.historical[i].month,stats.historical[i].year, lang));
      };
      let chartdata = {
        labels: month,
        datasets: [
          {
            label: 'Web',
            data: web,
            backgroundColor: '#cc8e00' 
          },
          {
            label:'GPS',
            data: gps,
            backgroundColor: '#f9d97b' 
          },
          {
            label:labelCalls,
            data: calls,
            backgroundColor: '#ffb600' 
          }
        ]
      }
      return chartdata;
    }
  fillAcciones(data:any){
    let historical =[] as any;
    let table = [] as any;
    this.stats.month = data.data.actions.month;
    this.stats.month_percent = data.data.actions.month_percent
    this.stats.average_location = data.data.actions.average_location
    this.stats.month_year_last_percent = data.data.actions.month_year_last_percent
    this.stats.web = data.data.actions.web
    this.stats.web_percent = data.data.actions.web_percent
    this.stats.gps = data.data.actions.gps
    this.stats.gps_percent = data.data.actions.gps_percent
    this.stats.calls = data.data.actions.calls
    this.stats.calls_percent = data.data.actions.calls_percent
    this.stats.historical = historical
      for (let i in data.data.actions.historical){
        let newHistorical={
          month: data.data.actions.historical[i].month,
          year: data.data.actions.historical[i].year,
          web: data.data.actions.historical[i].web.replaceAll('.',''),
          gps: data.data.actions.historical[i].gps.replaceAll('.',''),
          calls: data.data.actions.historical[i].calls.replaceAll('.',''),

        }
        historical.push(newHistorical);
      }
    this.stats.table = table
      for(let i in data.data.actions.table){
        let newTable={
          ranking: data.data.actions.table[i].ranking,
          code: data.data.actions.table[i].code,
          description: data.data.actions.table[i].description,
          total: data.data.actions.table[i].total,
          web: data.data.actions.table[i].web,
          gps: data.data.actions.table[i].gps,
          call: data.data.actions.table[i].calls
        }
        table.push(newTable);
      }
  }

  getStatsDefault(){
   return {
    month:0,
    month_percent:0,
    average_location:0,
    month_year_last_percent:0,
    web: 0,
    web_percent: 0,
    gps: 0,
    gps_percent: 0,
    calls: 0,
    calls_percent: 0,
    historical:
    [
      {
        month:0,
        year:0,
        web:0,
        gps:0,
        calls:0,
      },
      {
        month:0,
        year:0,
        web:0,
        gps:0,
        calls:0,
      },
    ],
    table:
    [
      {
        ranking:0,
        code: 0,
        description: "",
        total:0,
        web:0,
        gps:0,
        call:0
      },
      {
        ranking:0,
        code: 0,
        description: "",
        total:0,
        web:0,
        gps:0,
        call:0
      },
    ]
    }
  }
}
