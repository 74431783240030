import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

constructor(
  private http: HttpClient) { }

  public getCompanyLogged(): Observable<any>{

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    })
    let companyId:string = localStorage.getItem('companyId')!;
    return this.http.get(`${topConnect.apiUrl}empresas/${companyId}`,{headers:headers});
  }

  public getBrandsByCompanyLogged(): Observable<any>{

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    })
    let companyId:string = localStorage.getItem('companyId')!;
    return this.http.get(`${topConnect.apiUrl}marcas/getByEmpresa/${companyId}`,{headers:headers});
  }
}
