import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class CompaniesReviewsService {

  private BASE_URL = 'http://10.104.2.101:4444/';
  private apiUrl = 'http://10.104.2.101:4444/reviews/';

  constructor(private http: HttpClient) { }

  // Obtener todos los comentarios
  public getInfoReviews(id: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get<any[]>(`${this.apiUrl}?limit=100&marca=${id}`, { headers });
  }

  // Obtener solo comentarios no respondidos
  public getUnansweredReviews(id: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get<any[]>(`${this.apiUrl}?limit=100&reply_exists=false&marca=${id}`, { headers });
  }

  // Función para enviar respuestas
  public putAnswer(reviewId: string, textResponse: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // const url = `${this.putApi}/review/${reviewId}/reply`;
    const body = { comment: textResponse };

    return this.http.put(`${this.BASE_URL}/review/${reviewId}/reply`, body, { headers }).pipe(
      catchError((error) => {
        // Aquí puedes agregar lógica adicional si es necesario
        console.error('Error en el envío de respuesta:', error);
        throw error; // Propagar el error para que el componente también pueda manejarlo
      })
    );
  }
}
