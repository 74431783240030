import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationsService } from 'src/app/service/locations.service';
import { LocationsList } from './locations.model';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
/* eslint-disable */

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})

export class LocationsComponent implements OnInit {
  // Declaración de variable para almacenar los datos y los que vamos a usar en dentro de componente
  LocationsInfo: LocationsList;
  drawStats: DrawStatsService;
  // cat: any;
  // locationsDate: number;  //si fuera una fecha
  // verifyLocation: boolean; //para verificar, por ejemplo, si existe
  currentPage = 0;
  itemsPerPage = 14;
  loading: boolean = true;

  selectedLocation: any; // La ubicación seleccionada para editar


  constructor(private locationsService: LocationsService) { 
    this.LocationsInfo = this.getLocationDefault();
    this.drawStats = new DrawStatsService();
  
  }

  ngOnInit() {
    this.locationsLoad();
    this.LocationsInfo = this.getLocationDefault();
    
  }

  //por si hay que des-subscribirse, que de momento no hace falta
  // ngOnDestroy(): void {
  //   this.locationsLoad().unsubscribe();
  // }

  //cargar la lista de localidades
  public locationsLoad() {
    const brandId = '61de8f40bd62a94edb16ff7b';
    // const id = localStorage.getItem('companyId'); //cogemos el id del localStorage
    this.locationsService.getInfoLocations(brandId)
      .subscribe(response => {
        this.fillLocationData(response);
        this.loading = false; // Marcar como no cargando después de obtener los comentarios
    },
    (error) => {
      console.error('Error al obtener las ubicaciones:', error);
      this.loading = false; // Marcar como no cargando en caso de error
    });
  }

//función para rellenar la petición que hagamos con los datos que queremos
  public fillLocationData(dataPetiton: any) {
    // console.log(dataPetiton, 'esto es la peticion de datos');
    const locationData = [];
    
    for (let i in dataPetiton) {
      const newLocationsData = {
          codigoTienda: dataPetiton[i].codigo_tienda,
          nombre: dataPetiton[i].nombre,
          direccion: dataPetiton[i].direccion,
          localidad: dataPetiton[i].localidad,
          provincia: dataPetiton[i].provincia,
          totalReviews: dataPetiton[i].total_reviews,
          rating: this.drawStats.fixedNumber(dataPetiton[i].rating),
          id: dataPetiton[i]._id,
        };
        
        locationData.push(newLocationsData);
    }
    
    this.LocationsInfo.locations = locationData;
    console.log(this.LocationsInfo.locations, 'esto son los datos guardados en dataLocations');
  }

  //funcion para editar
  public onSaveLocation(updatedLocation: any) {
    // Actualizar la ubicación en la lista
    const index = this.LocationsInfo.locations.findIndex(loc => loc.codigoTienda === updatedLocation.codigoTienda);
    if (index !== -1) {
      this.LocationsInfo.locations[index] = updatedLocation;
    }
  }

  //funcion para seleccionar la ubicación 
  public onLocationClick(location: any) {
    this.selectedLocation = location;
  }


  //para poder reutulizar esta funcion en otros componentes la ponemos dentro de drawStatsService 
  // public fixedNumber (ratingData: string) {
  //   let numberRating = parseFloat(ratingData);
  //     return numberRating.toFixed(2);
  // }
  

  public getLocationDefault(){
    return {
      locations: [
        {
          codigoTienda: 'sin datos',
          nombre: 'sin datos',
          direccion: 'sin datos',
          localidad: 'sin datos',
          provincia: 'sin datos',
          totalReviews: 0,
          rating: 0,
          id: '0',
        },

        {
          codigoTienda: 'sin datos',
          nombre: 'sin datos',
          direccion: 'sin datos',
          localidad: 'sin datos',
          provincia: 'sin datos',
          totalReviews: 0,
          rating: 0,
          id: '0',

        }
      ]
    }
  }
}


