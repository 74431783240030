<div class="container-fluid">

    <div class="row">
        <!-- SIDEBAR -->
        <div class="col-2 bg-dark position-fixed min-vh-100 px-2" id="sticky-sidebar">
            <a href="" class="text-white text-decoration-none mt-1 d-flex align-items-center mt-4 mt-sm-0"
                role="button">
                <span class="fs-4 py-3" id="logo"><img class="logo-sidebar" src="../../../assets/img/logo.png"
                        alt="logo top local"></span>
            </a>

            <hr>

            <ul class="nav nav-pills flex-column mt-4 mt-sm-0" id="menu">
                <li class="nav-item py-sm-0">
                    <a [routerLink]="'/companies-reviews'" class="nav-link text-white my-2 link-hover"
                        aria-current="page">
                        <i class="fa-solid fa-ranking-star fa-l"></i>
                        <span class="fs-4 ms-2 d-none d-sm-inline">Reviews</span>
                    </a>
                </li>

                <li class="nav-item py-2 py-sm-0">
                    <a [routerLink]="'/locations'" class="nav-link text-white my-2 link-hover" aria-current="page">
                        <i class="fa-solid fa-location-dot fa-l"></i>
                        <span class="fs-4 ms-2 d-none d-sm-inline ms-2">Ubicaciones</span>
                    </a>
                </li>

                <!-- <li class="nav-item py-2 py-sm-0">
                    <a href="" class="nav-link"></a>
                </li> -->
            </ul>
            <button class="btn btn-dark text-light btn-back">
                <a href=""><i class="fa-regular fa-circle-left"></i>
                    <span> atrás</span>
                </a>
            </button>
        </div>
        <!-- END SIDEBAR -->

        <!-- TABLE CONTENT -->
        <div class="col offset-2 table-responsive" id="main">
            <div *ngIf="loading" class="custom-spinner"></div>
            <table class="table table-striped table-hover caption-top">
                <caption>Lista de ubicaciones</caption>
                <thead class="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th>Localidad</th>
                        <th>Provincia</th>
                        <th>Reviews</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let location of LocationsInfo.locations | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" (click)="onLocationClick(location)">
                        <td>{{ location.codigoTienda }}</td>
                        <td><a [routerLink]="'/edit-location/' + location.id">{{ location.nombre }}</a></td>
                        <td>{{ location.direccion }}</td>
                        <td>{{ location.localidad }}</td>
                        <td>{{ location.provincia }}</td>
                        <td>{{ location.totalReviews }}</td>
                        <td>{{ location.rating }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="fixed-bottom bg-white offset-2 d-flex flex-center justify-content-lg-center pt-4">
                <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
            </div>
            
        </div>
    </div>
</div>




<!-- 65b0e682e1133c6aad2bb306 -->