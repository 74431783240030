import { Component, OnInit, Input } from '@angular/core';
import { Review } from './review-bubble.model';

@Component({
  selector: 'app-review-bubble',
  templateUrl: './review-bubble.component.html',
  styleUrls: ['./review-bubble.component.scss']
})
export class ReviewBubbleComponent implements OnInit {
  @Input() review: Review;

  constructor() { }

  ngOnInit(): void {
    // Implementación de ngOnInit (puede estar vacío si no se requiere ninguna lógica específica)
  }

  responderRevision() {
    if (this.review.response) {
      this.review.status = 'answered';
    }
  }
}


