import { Component, OnInit } from '@angular/core';
import { D3ServiceService } from 'src/app/service/d3-service.service';
import * as d3 from 'd3';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor(public d3ServiceService: D3ServiceService) { }

  ngOnInit(): void {
    this.d3ServiceService.getInfo().subscribe((data) => {
      console.log(data);
    });
  }

  public draw() {
    var svg = d3.select('#map-1'),
      width = +svg.attr('width'),
      height = +svg.attr('height');

    // Creamos la proyección  (ver Proyecciones abajo)
    var projection = d3.geoMercator()
      .scale(2200)
      .center([0, 40])
      .translate([width / 1.7, height / 2]);
    //.translate([350, 200]);         // Otros atributos
    //.rotate([122.4194, -37.7749])
    //.clipAngle(180 - 1e-3)
    //.precision(0.1);

    // Creamos el path añadiendo la proyección
    var path = d3.geoPath(projection)

    // Creamos una rejilla que se repita cada 2 grados tanto
    //   en direcciones norte-sur como este-oeste
    var graticule = d3.geoGraticule().step([2, 2]);

    // Añadimos la rejilla
    svg.append('path')
      .datum(graticule)
      .attr('class', 'graticule')
      .attr('d', path);
  }


}
