<div class="cortina" id="cortina">
  <span class="spinner hidden" id="loaderSpinner"></span>
<app-navegador></app-navegador>
<h1 class="text-center titulo">{{'views' | translate}}</h1>
<div class="container">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="text-center">
          <p class="top-font-3 bold" >{{'total-month' | translate}}</p>
          <h1 class="top-font-1 bold">{{stats.month}}<span [ngClass]="drawStats.getClassByNumber(stats.month_percent)" class="top-font-2 color-red padding-bottom"><img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt=""> {{stats.month_percent}}%</span></h1>
          <div class="row">
            <div class="col-md">
              <h4 class="bold">{{'local-average' | translate}}:<span [ngClass]="drawStats.getClassByNumber(stats.average_location)" class="top-font-4 color-red"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.average_location}}%</span></h4>
            </div>
            <div class="col-md">
              <h4 class="bold">{{'compare-last-year' | translate}}:<span [ngClass]="drawStats.getClassByNumber(stats.month_year_last_percent)" class="top-font-4 color-red"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.month_year_last_percent}}%</span></h4>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <p class="top-font-5"><img class="img-info" src="../assets/img/search.svg" alt=""> {{'views-searchs' | translate}}</p>
              </div>
              <div class="col-md-12">
                <h3 class="top-font-3 bold">{{stats.search}}<span [ngClass]="drawStats.getClassByNumber(stats.search_percent)" class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.search_percent}}%</span></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <p class="top-font-5"><img class="img-info" src="../assets/img/google.svg"  alt=""> {{'views-maps' | translate}} </p>
              </div>
              <div class="col-md-12">
                <h3 class="top-font-3 bold">{{stats.maps}}<span [ngClass]="drawStats.getClassByNumber(stats.maps_percent)" class="top-font-4 color-red padding-bottom"><img class="img-last-month img-down" src="../assets/img/down.png" alt=""><img class="img-last-month img-up" src="../assets/img/up.png" alt=""> {{stats.maps_percent}}%</span></h3>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div id="canvas2"><canvas class="grafic" id="myChart" width="620" height="400"></canvas></div>
    </div>
    <table class=" col-md table table-striped">
      <thead>
        <tr class="text-center">
          <th scope="col">{{'ranking' | translate}}</th>
          <th scope="col">{{'code' | translate}}</th>
          <th scope="col">{{'description' | translate}}</th>
          <th scope="col">{{'views-total' | translate}}</th>
          <th scope="col">{{'views-searchs' | translate}}</th>
          <th scope="col">{{'views-maps' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center top-font-6" *ngFor="let stat of stats.table">
          <td>{{stat.ranking}}</td>
          <td>{{stat.code}}</td>
          <td>{{stat.description}}</td>
          <td>{{stat.total}}</td>
          <td>{{stat.search}}</td>
          <td>{{stat.map}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>