<div class="container-fluid">

  <div class="row">
    <!-- SIDEBAR -->
    <div class="col-2 bg-dark position-fixed min-vh-100 px-2" id="sticky-sidebar">
      <a href="" class="text-white text-decoration-none mt-1 d-flex align-items-center mt-4 mt-sm-0" role="button">
        <span class="fs-4 py-3" id="logo"><img class="logo-sidebar" src="../../../assets/img/logo.png"
            alt="logo top local"></span>
      </a>

      <hr>

      <ul class="nav nav-pills flex-column mt-4 mt-sm-0" id="menu">
        <li class="nav-item py-sm-0">
          <a [routerLink]="'/companies-reviews'" class="nav-link text-white my-2 link-hover" aria-current="page">
            <i class="fa-solid fa-ranking-star fa-l"></i>
            <span class="fs-4 ms-2 d-none d-sm-inline">Reviews</span>
          </a>
        </li>

        <li class="nav-item py-2 py-sm-0">
          <a [routerLink]="'/locations'" class="nav-link text-white my-2 link-hover" aria-current="page">
            <i class="fa-solid fa-location-dot fa-l"></i>
            <span class="fs-4 ms-2 d-none d-sm-inline ms-2">Ubicaciones</span>
          </a>
        </li>

        <!-- <li class="nav-item py-2 py-sm-0">
                  <a href="" class="nav-link"></a>
              </li> -->
      </ul>
      <button class="btn btn-dark text-light btn-back" [routerLink]="['/locations']">
        <i class="fa-regular fa-circle-left"></i>
        <span> atrás</span>
      </button>
    </div>
    <!-- END SIDEBAR -->








    <!--. *ngIf="location" -->

    <!-- TABLE CONTENT -->
    <div class="col offset-2 table-responsive" id="main" >
      <!-- <div *ngIf="loading" class="custom-spinner"></div> -->

      <div class="edit-location-form mt-4">
        <h2 class="mb-4">Detalles y Edición de Ubicación</h2>

        <div class="col-md-6 mb-3">
          <label for="codigoTienda" class="form-label">Código de Tienda:</label>
          <input type="text" [(ngModel)]="locationInfo.codigoTienda " name="codigoTienda" class="form-control" readonly [value]="locationInfo.codigoTienda">
        </div>

        <div class="col-md-6 mb-3">
          <label for="nombre" class="form-label">Nombre:</label>
          <input type="text" [(ngModel)]="locationInfo.nombre" name="nombre" class="form-control">
        </div>

        <div class="col-md-6 mb-3">
          <label for="direccion" class="form-label">Dirección:</label>
          <input type="text" [(ngModel)]="locationInfo.direccion" name="direccion" class="form-control">
        </div>

        <div class="col-md-6 mb-3">
          <label for="direccion" class="form-label">Localidad:</label>
          <input type="text" [(ngModel)]="locationInfo.localidad" name="localidad" class="form-control">
        </div>

        <div class="col-md-6 mb-3">
          <label for="direccion" class="form-label">Provincia:</label>
          <input type="text" [(ngModel)]="locationInfo.provincia" name="provincia" class="form-control">
        </div>
        

        <!-- Agrega más campos según sea necesario -->

        <div class="mt-4">
          <button (click)="onSave()" class="btn btn-dark">Guardar Cambios</button>
        </div>
      </div>

<!--       
      <div *ngIf="!location">
        <p class=" col offset-2 m-5">Location is not available yet.</p>
      </div> -->

    </div>