import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {topConnect} from '../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccionesService {

  constructor(private http: HttpClient) { }

  public getInfo(month:number,year:number,id:string,type:string): Observable<any>{
  	let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
    if (id=="0"){
      type = "empresa";
      id = localStorage.getItem('companyId')!;
    }
    return this.http.get(`${topConnect.backUrl}/actions/${type}/${id}/${year}/${month}/`,{headers:headers});
  }
}
