import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompaniesReviews } from './companies-reviews.model';
import { CompaniesReviewsService } from 'src/app/service/companies-reviews.service';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
/* eslint-disable */
@Component({
  selector: 'app-companies-reviews',
  templateUrl: './companies-reviews.component.html',
  styleUrls: ['./companies-reviews.component.scss']
})

export class CompaniesReviewsComponent implements OnInit {
  reviews: CompaniesReviews;
  drawStats: DrawStatsService;
  comments: any[] = [];
  responseText: { [commentId: string]: string } = {};
  // id: string = '58da19e2c01e2cfa6b2f2231';
  currentPage = 1;
  itemsPerPage = 10;
  // errorMessages: boolean = false; //
  errorMessageText: string = '';
  errorMessages: { [commentId: string]: string } = {};
  errorTimeouts: { [commentId: string]: any } = {}; // Almacenar los identificadores de los temporizadores
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  loadingOptions: boolean = false;

  constructor(private reviewsService: CompaniesReviewsService) {
    this.reviews = this.getReviewsDefault();
    this.drawStats = new DrawStatsService();
  }

  ngOnInit(): void {
    this.getComments();
  }

  //función para obtener los comentarios
  public getComments() {
    const id = '58da19e2c01e2cfa6b2f2231';
    this.reviewsService.getInfoReviews(id).subscribe((comments) => {
      console.log('Comentarios obtenidos:', comments);
      this.comments = comments;
      this.fillReviewsData(comments);
      this.loading = false; // Marcar como no cargando después de obtener los comentarios
    },
    (error) => {
      console.error('Error al obtener comentarios:', error);
      this.loading = false; // Marcar como no cargando en caso de error
    });

  }

  //funcion para mostrar las no respondidas
  public showNotAnswered() {
    this.loadingOptions = true; // Mostrar spinner al hacer clic en "Mostrar Todas las Reseñas"
    const id = '58da19e2c01e2cfa6b2f2231';
    this.reviewsService.getUnansweredReviews(id).subscribe((comments) => {
      console.log('Comentarios no respondidos obtenidos:', comments);
      // this.filtredComments = comments; // si usamos éste, debemos usar los datos que nos llegan directamente de la url, es mejor usar el fillReviewsData() que hemos hecho más abajo para tratar los datos como queramos.
      this.fillReviewsData(comments);
      this.loadingOptions = false; // Ocultar spinner después de obtener los comentarios no respondidos
        },
        (error) => {
            console.error('Error al obtener comentarios no respondidos:', error);
            this.loadingOptions = false; // Ocultar spinner en caso de error
        }
    )
  }

  //función para llenar la petición de los datos que queremos
  public fillReviewsData(dataPetiton: any) {
    console.log(dataPetiton, 'peticion de los datos review');
    const reviewsData = [];

    for (let i in dataPetiton) {
      const isAnswered = dataPetiton[i].reply !== undefined && dataPetiton[i].reply !== null;
      const newReviewsData = {
        id: dataPetiton[i]._id,
        comment: dataPetiton[i].comment,
        author: dataPetiton[i].author_name,
        date: dataPetiton[i].updated,
        rating: dataPetiton[i].rating,
        isAnswered: isAnswered,
        responses: isAnswered ? [{ updateTime: dataPetiton[i].reply.updateTime, comment: dataPetiton[i].reply.comment }] : [],
      };
      reviewsData.push(newReviewsData);
    }
    this.reviews.review = reviewsData;
    console.log(this.reviews.review, 'estos son los datos guardados en reviewsData');
  }

  //funcion para el submenú desplegable del sidebar
  public toggleSubMenu() {
    const subMenu = document.getElementById('subMenu');
    if (subMenu) {
      subMenu.style.display = subMenu.style.display === 'block' ? 'none' : 'block';
    }
  }

  //función para desabilitar el botón de "responder" si no hay nada escrito en el text area
  public checkTextarea(id: string): void {
    const textareaContent = this.responseText[id];
    console.log('Contenido del textarea:', textareaContent);
  }

  public isTextareaEmpty(id: string): boolean {
    return !this.responseText[id] || this.responseText[id].trim() === '';
  }

  public responseComment(reviewInfo: any, responseText: string) {
    // Verificamos que el comentario tenga una propiedad 'responses'
    if (!reviewInfo.responses) {
      reviewInfo.responses = [];
    }

    // Agregamos la respuesta al array 'responses'
    reviewInfo.responses.push({
      updateTime: new Date().toISOString(),
      comment: responseText
    });

    // Marcamos el comentario como respondido
    reviewInfo.isAnswered = true;

    // Limpiar el textarea después de mostrar el mensaje de error
    this.responseText[reviewInfo.id] = '';

    // Llamada al servicio para enviar la respuesta al servidor
    this.reviewsService.putAnswer(reviewInfo.id, responseText).subscribe(
      (response) => {
        // Manejar la respuesta del servidor si es necesario
        console.log('Respuesta del servidor:', response);
      },
      (error: HttpErrorResponse) => {
        // Manejar errores si la solicitud al servidor falla
        console.error('Error al enviar respuesta al servidor:', error);

        if (error.status === 401) {
          // Manejar el error específico de autorización
          this.errorMessages[reviewInfo.id] = 'No estás autorizado para realizar esta acción. Por favor, inicia sesión.';
        } else {
          // Manejar otros errores genéricos
          this.errorMessages[reviewInfo.id] = 'Error en el envío de respuesta. Por favor, inténtelo de nuevo.';
        }

        // Establecer el temporizador para limpiar el mensaje de error después de 5 segundos
        this.errorTimeouts[reviewInfo.id] = setTimeout(() => {
          reviewInfo.isAnswered = false; // Marcamos el comentario como no respondido
          this.errorMessages[reviewInfo.id] = ''; // Limpiar el mensaje de error
        }, 5000);
      }
    );
  }

  public ngOnDestroy() {
    // Limpiar temporizadores y desuscribirse para evitar pérdida de memoria
    Object.values(this.errorTimeouts).forEach(timerId => clearTimeout(timerId));
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  //definimos una función que cargará los datos por defecto si no hay datos y/o mientras carga la página
  public getReviewsDefault() {
    return {
      review: [
        {
          id: '',
          comment: 'sin datos',
          author: 'sin datos',
          date: 0,
          rating: 0,
          isAnswered: false,
          responses: [],
        }
      ]
    };
  }
}
