<div class="container-fluid">
    <router-outlet></router-outlet>
    <div class="row">
        <!-- SIDEBAR -->
        <div class="col-2 bg-dark position-fixed min-vh-100 px-2" id="sticky-sidebar">
            <a href="" class="text-white text-decoration-none mt-1 d-flex align-items-center mt-4 mt-sm-0"
                role="button">
                <span class="fs-4 py-3" id="logo"><img class="logo-sidebar" src="../../../assets/img/logo.png"
                        alt="logo top local"></span>
            </a>

            <hr>

            <ul class="nav nav-pills flex-column mt-4 mt-sm-0" id="menu">
                <li class="nav-item py-2 py-sm-0">
                    <a [routerLink]="'/companies-reviews'"
                        class="nav-link collapsed text-truncate text-white text-start my-2 link-hover"
                        (click)="toggleSubMenu()">
                        <i class="fa-solid fa-ranking-star fa-l"></i>
                        <span class="fs-4 ms-2 d-none d-sm-inline">Reviews <i class="fa-solid fa-caret-down"></i></span>
                    </a>
                    <ul class="sub-menu" id="subMenu">
                        <li class="nav-item py-2 py-sm-0 bg-dark">
                            <a class="nav-link py-2 text-white text-start my-2 link-hover" (click)="showNotAnswered()">
                                <i class="fa-solid fa-comments"></i>
                                <span class="fs-4 ms-2 d-none d-sm-inline ms-2 fst-italic">Sin responder</span>
                            </a>
                        </li>
                        <li class="dnav-item py-2 py-sm-0 bg-dark">
                            <a class="nav-link py-2 text-white text-start my-2 link-hover" (click)="getComments()">
                                <i class="fa-solid fa-clipboard-list"></i>
                                <span class="fs-4 ms-2 d-none d-sm-inline ms-2 fst-italic">Mostrar todas</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item py-2 py-sm-0">
                    <a [routerLink]="'/locations'" class="nav-link text-white text-start my-2 link-hover"
                        aria-current="page">
                        <i class="fa-solid fa-location-dot fa-l"></i>
                        <span class="fs-4 ms-2 d-none d-sm-inline ms-2">Ubicaciones</span>
                    </a>
                </li>
                <!-- <li class="nav-item py-2 py-sm-0">
                    <a [routerLink]="'/locations'" class="nav-link text-white my-2 link-hover" aria-current="page">
                        <i class=" "></i>
                        <span class=" "></span>
                    </a>
                </li> -->
            </ul>

            <button class="btn btn-dark text-light btn-back">
                <a href=""><i class="fa-regular fa-circle-left"></i>
                    <span> atrás</span>
                </a>
            </button>
        </div>
        <!-- END SIDEBAR -->

        <!-- Content start -->
        <div class="row">
            <div class="col-9 offset-2 col-md-8 offset-3 p-0">
                <br>
                <div *ngIf="loading" class="custom-spinner"></div>
                <div *ngIf="loadingOptions" class="custom-spinner"></div>
                <br>
                <div *ngFor="let reviewInfo of reviews.review | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
                    class="comment-card p-3 p-lg-5 my-5">
                    <p class="fw-title">Comentario:</p>

                    <div class="comment-content p-2">{{ reviewInfo.comment }}</div>
                    <div class="comment-author px-2 mt-4">Autor: {{ reviewInfo.author }}</div>
                    <div class="comment-date px-2">Fecha: {{ reviewInfo.date | date:'medium'}}</div>

                    <div class="rating">
                        <span class="rating-stars">
                            <span *ngFor="let star of [1, 2, 3, 4, 5]" class="star"
                                [class.star-filled]="star <= reviewInfo.rating"
                                [class.star-empty]="star > reviewInfo.rating">
                                &#9733; <!--Caracter de estrella-->
                            </span>
                        </span>
                    </div>
                    <hr class="hr-comment">

                    <!-- Mostrar respuestas si existen -->
                    <div *ngIf="reviewInfo.isAnswered && reviewInfo.responses && reviewInfo.responses.length > 0">
                        <p class="fw-title">Respuesta:</p>
                        <ul class="px-4">
                            <!-- Utilizamos *ngFor para iterar sobre las respuestas -->
                            <li *ngFor="let response of reviewInfo.responses">
                                <p>{{ response.comment }}</p>
                                <p class="comment-date">Última actualización: {{ response.updateTime | date:'medium' }}
                                </p>
                            </li>
                        </ul>
                    </div>

                    <!-- Aquí agregamos la sección de respuesta -->
                    <div *ngIf="!reviewInfo.isAnswered" class="form mt-4">
                        <textarea [(ngModel)]="responseText[reviewInfo.id]" class="form-control my-3"
                            placeholder="Responder reseña" id="floatingTextarea"
                            (input)="checkTextarea(reviewInfo.id)"></textarea>

                        <button class="btn btn-success"
                            (click)="responseComment(reviewInfo, responseText[reviewInfo.id])"
                            [disabled]="isTextareaEmpty(reviewInfo.id)">Responder</button>
                    </div>

                    <!-- Mensajes de error de envío de respuesta -->
                    <div *ngIf="errorMessages[reviewInfo.id]" class="error-message">
                        {{ errorMessages[reviewInfo.id] }}
                    </div>

                    <!-- Paginación al final de la página -->
                    <div class="fixed-bottom bg-white offset-2 d-flex flex-center justify-content-center pt-4">
                        <pagination-controls (pageChange)="currentPage = $event" previousLabel="" nextLabel=""
                            [maxSize]="7"></pagination-controls>
                    </div>
                </div>
            </div>