<app-navegador></app-navegador>
<table class='table table-hover marketon-table'>
    <thead>
        <tr class="text-center">
          <th class="text-center" colspan="1">{{'date' | translate}}</th>
          <th  class="text-center" colspan="2">{{'searchs'| translate}}</th>
          <th  class="text-center" colspan="3">{{'views' | translate}}</th>
          <th  class="text-center" colspan="4">{{'actions' | translate}}</th>
          <th  class="text-center" colspan="5">Rating</th>
        </tr>
        <tr>
            <th class="text-center">{{'dates' | translate}}</th>
            <th class="text-center">{{'totals' | translate}}</th>
            <th class="text-center">{{'searchs-directs' | translate}}</th>
            <th class="text-center">{{'searchs-indirects' | translate}}</th>
            <th class="text-center">{{'searchs-total' | translate}}</th>
            <th class="text-center">{{'views-searchs' | translate}}</th>
            <th class="text-center">{{'views-maps' | translate}}</th>
            <th class="text-center">{{'views-total' | translate}}</th>
            <th class="text-center">{{'actions-web' | translate}}</th>
            <th class="text-center">{{'actions-indication' | translate}}</th>
            <th class="text-center">{{'actions-calls' | translate}}</th>
            <th class="text-center">Rating</th>
            <th class="text-center">Reviews</th>
        </tr>
    </thead>
    <tbody>
        <tr class="text-center" *ngFor="let stat of stats.historico; index as i"> 
          <td class="top-font-4">{{stat.fecha}}-{{stat.ano}}</td>
          <td [ngClass]="drawStats.getColorHistory('totalesBusquedas',stat.totalesBusquedas,i,stats.historico)"><p class="celda">{{stat.totalesBusquedas}}</p><span [ngClass]="drawStats.getColorHistory('totalesBusquedas',stat.totalesBusquedas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('totalesBusquedas',i,stats.historico)}}%</span></td>
          <td [ngClass]="drawStats.getColorHistory('directas',stat.directas,i,stats.historico)"><p class="celda">{{stat.directas}}</p><span [ngClass]="drawStats.getColorHistory('directas',stat.directas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('directas',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('indirectas',stat.indirectas,i,stats.historico)"><p class="celda">{{stat.indirectas}}</p><span [ngClass]="drawStats.getColorHistory('indirectas',stat.indirectas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('indirectas',i,stats.historico)}}%</span></td>
          <td [ngClass]="drawStats.getColorHistory('totalesVistas',stat.totalesVistas,i,stats.historico)"><p class="celda">{{stat.totalesVistas}}</p><span [ngClass]="drawStats.getColorHistory('totalesVistas',stat.totalesVistas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('totalesVistas',i,stats.historico)}}%</span></td>
          <td [ngClass]="drawStats.getColorHistory('busquedas',stat.busquedas,i,stats.historico)"><p class="celda">{{stat.busquedas}}</p><span [ngClass]="drawStats.getColorHistory('busquedas',stat.busquedas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('busquedas',i,stats.historico)}}%</span></td>
          <td [ngClass]="drawStats.getColorHistory('maps',stat.maps,i,stats.historico)"><p class="celda">{{stat.maps}}</p><span [ngClass]="drawStats.getColorHistory('maps',stat.maps,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('maps',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('totalesAcciones',stat.totalesAcciones,i,stats.historico)"><p class="celda">{{stat.totalesAcciones}}</p><span [ngClass]="drawStats.getColorHistory('totalesAcciones',stat.totalesAcciones,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('totalesAcciones',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('web',stat.web,i,stats.historico)"><p class="celda">{{stat.web}}</p><span [ngClass]="drawStats.getColorHistory('web',stat.web,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('web',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('indicaciones',stat.telefono,i,stats.historico)"><p class="celda">{{stat.indicaciones}}</p><span [ngClass]="drawStats.getColorHistory('indicaciones',stat.telefono,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('indicaciones',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('telefono',stat.telefono,i,stats.historico)"><p class="celda">{{stat.telefono}}</p><span [ngClass]="drawStats.getColorHistory('telefono',stat.telefono,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('telefono',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('criticas',stat.criticas,i,stats.historico)"><p class="celda">{{stat.criticas}}</p><span [ngClass]="drawStats.getColorHistory('criticas',stat.criticas,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('criticas',i,stats.historico)}} %</span></td>
          <td [ngClass]="drawStats.getColorHistory('clasificacion',stat.clasificacion,i,stats.historico)"><p class="celda">{{stat.clasificacion}}</p><span [ngClass]="drawStats.getColorHistory('clasificacion',stat.clasificacion,i,stats.historico)" class="top-font-5 color-red padding-bottom"><i class="fa fa-arrow-down" aria-hidden="true"></i><i class="fa fa-arrow-up" aria-hidden="true"></i> {{drawStats.getPercentage('clasificacion',i,stats.historico)}} %</span></td>
        </tr>
    </tbody>
</table>

