import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  private BASE_URL = 'http://10.104.2.101:4444/';


  constructor(private http: HttpClient) { }

  public getInfoLocations(id: string ){
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get(`http://10.104.2.101:4444/localidad/get-by-marca/${id}`, {headers});
  }

  //.*******************************************************************************

  // Nuevo método para obtener ubicación por código de tienda
  public getLocationByCodigoTienda(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // Cambiado a un método GET y corregido la ruta.
    return this.http.get(`${this.BASE_URL}localidad/${id}`, { headers });
  }
  
  // Nuevo método para actualizar la ubicación
  public updateLocation(location: any): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // Cambiado a un método PUT y corregido la ruta.
    return this.http.put(`${this.BASE_URL}localidad/update/${location._id}`, location, { headers });
  }

  public getLocationById(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get(`${this.BASE_URL}localidades/${id}`, { headers });
  }
}