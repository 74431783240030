import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class VisionadosService {

  constructor(private http: HttpClient) {}
  public getInfo(month:number,year:number,id:string,type:string): Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
    if (id=="0"){
      type = "empresa";
      id = localStorage.getItem('companyId')!;
    }
    return this.http.get(`${topConnect.backUrl}/views/${type}/${id}/${year}/${month}/`,{headers:headers});
  }
}
