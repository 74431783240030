/*eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Panel } from './panel.model';
import { BusquedasService } from 'src/app/service/busquedas.service';
import { FilterService } from 'src/app/service/filter.service';
import { VisionadosService } from 'src/app/service/visionados.service';
import { AccionesService } from 'src/app/service/acciones.service';
import { ReputacionService } from 'src/app/service/reputacion.service';
import { TipologiasService } from 'src/app/service/tipologias.service';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  stats: any;
  drawStats: DrawStatsService;
  arrayStars: Array<number>;
  canvas: any;
  newCanvasGlobal: any;
  chartUpdateGlobal: any;
  divCanvasGlobal: any;
  ctx: any;
  public customDateSubscription: Subscription;
  public customLangSubscription: Subscription;
  public customBrandSubscription: Subscription;
  public customStickerSubscription: Subscription


  constructor(
    public router: Router,
    public busquedasService: BusquedasService,
    public visionadosService: VisionadosService,
    public accionesService: AccionesService,
    public reputacionService: ReputacionService,
    public tipologiasService: TipologiasService,
    private drawStatsService: DrawStatsService,
    public langMonthService: LangMonthService,
    private filter: FilterService,
    private _route: ActivatedRoute) {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
    }
    this.drawStats = new DrawStatsService();
    this.arrayStars = [1, 2, 3, 4, 5];
    this.stats = this.getStatsDefault();
  }

  ngOnInit(): void {
    
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      this.drawStats.addSpinner();
      const idBrand = this.filter.getBrandCurrentValue();
      const idSticker = this.filter.getStickerCurrentValue();
      if (date !== '0' && idSticker === '0' && idBrand ==='0') {
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();

          } else {
            this.fillDispositivos(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
        this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillVisionados(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillAcciones(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillReputacion(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillTipologias(dataStats);
            this.drawStats.removeSpinner();
          }
        });
      } if (date !== '0' && idBrand !== '0') {
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          } else {
            this.fillDispositivos(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
        this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillVisionados(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillAcciones(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillReputacion(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idBrand, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillTipologias(dataStats);
            this.drawStats.removeSpinner();
          }
        });
      }
      if (date !== '0' && idSticker !== '0') {
        const newDate = new Date(date);
        const type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idSticker, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          } else {
            this.fillDispositivos(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
        this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idSticker, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillVisionados(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idSticker, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillAcciones(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idSticker, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillReputacion(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), idSticker, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillTipologias(dataStats);
            this.drawStats.removeSpinner();
          }
        });
      }
    });
    this.customBrandSubscription = this.filter.customBrand.subscribe(brandId => {
      const date: string = this.filter.getDateCurrentValue();
      if (brandId !== '0') {
        this.drawStats.addSpinner();
        const newDate = new Date(date);
        this.filter.changeType('marca');
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, 'marca').subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          } else {
            this.fillDispositivos(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
        this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, 'marca').subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillVisionados(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, 'marca').subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillAcciones(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, 'marca').subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillReputacion(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, 'marca').subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillTipologias(dataStats);
            this.drawStats.removeSpinner();
          }
        });

      }
      if (brandId === '0' && date !== "0") {
        this.drawStats.addSpinner();
        const newDate = new Date(date);
        let type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          } else {
            this.fillDispositivos(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
        this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillVisionados(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillAcciones(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillReputacion(dataStats);
            this.drawStats.removeSpinner();
          }
        });
        this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), brandId, type).subscribe((dataStats) => {
          if (dataStats.data === null) {
            this.stats = this.getStatsDefault();
            this.drawStats.removeSpinner();
          } else {
            this.fillTipologias(dataStats);
            this.drawStats.removeSpinner();
          }
        });

      }

    });
    // this.customStickerSubscription =this.filter.customSticker.subscribe(stickerId => {
    //   if (stickerId !== '0') {
    //     this.drawStats.addSpinner();
    //     const date: string = this.filter.getDateCurrentValue();
    //     const newDate = new Date(date);
    //     this.filter.changeType('sticker');
    //     let lang = this.filter.getLangCurrentValue();
    //     this.busquedasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), stickerId, 'sticker').subscribe((dataStats) => {
    //       if (dataStats.data === null) {
    //         this.stats = this.getStatsDefault();
    //         this.createChart(lang);
    //         this.drawStats.removeSpinner();
    //       } else {
    //         this.fillDispositivos(dataStats);
    //         this.createChart(lang);
    //         this.drawStats.removeSpinner();
    //       }
    //     });
    //     this.visionadosService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), stickerId, 'sticker').subscribe((dataStats) => {
    //       if (dataStats.data === null) {
    //         this.stats = this.getStatsDefault();
    //         this.drawStats.removeSpinner();
    //       } else {
    //         this.fillVisionados(dataStats);
    //         this.drawStats.removeSpinner();
    //       }
    //     });
    //     this.accionesService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), stickerId, 'sticker').subscribe((dataStats) => {
    //       if (dataStats.data === null) {
    //         this.stats = this.getStatsDefault();
    //         this.drawStats.removeSpinner();
    //       } else {
    //         this.fillAcciones(dataStats);
    //         this.drawStats.removeSpinner();
    //       }
    //     });
    //     this.reputacionService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), stickerId, 'sticker').subscribe((dataStats) => {
    //       if (dataStats.data === null) {
    //         this.stats = this.getStatsDefault();
    //         this.drawStats.removeSpinner();
    //       } else {
    //         this.fillReputacion(dataStats);
    //         this.drawStats.removeSpinner();
    //       }
    //     });
    //     this.tipologiasService.getInfo(newDate.getMonth() + 1, newDate.getFullYear(), stickerId, 'sticker').subscribe((dataStats) => {
    //       if (dataStats.data === null) {
    //         this.stats = this.getStatsDefault();
    //         this.drawStats.removeSpinner();
    //       } else {
    //         this.fillTipologias(dataStats);
    //         this.drawStats.removeSpinner();
    //       }
    //     });

    //   }

    // });
    this.customLangSubscription = this.filter.customLang.subscribe(lang => {
      this.createChart(lang);
    });
  }

  ngOnDestroy(): void{
    this.customDateSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
    this.customBrandSubscription.unsubscribe()
    // this.customStickerSubscription.unsubscribe()
  }
  createChart(lang: any) {
    this.canvas = document.getElementById('chartReputationPanel');
    if (this.canvas) {
      this.canvas.remove();
      this.newCanvasGlobal = document.createElement('canvas');
      this.newCanvasGlobal.setAttribute('id', 'chartReputationPanel');
      this.newCanvasGlobal.setAttribute('width', 400);
      this.newCanvasGlobal.setAttribute('height', 150);
      this.newCanvasGlobal.setAttribute('class', 'grafic');
      this.divCanvasGlobal = document.getElementById('canvasPanel');
      this.divCanvasGlobal.appendChild(this.newCanvasGlobal);

      this.canvas = this.newCanvasGlobal;
      this.ctx = this.canvas.getContext('2d');

      let dataChartGlobal = this.dataChartDevices(this.stats, lang);

      this.chartUpdateGlobal = new Chart(this.ctx, {
        type: 'pie',
        data: dataChartGlobal
      });

      // Chart.pluginService.register({
      //   beforeDraw: function(chart) {
      //     console.log(chart.chart.width)
      //     var width = chart.chart.width,
      //         height = chart.chart.height,
      //         ctx = chart.chart.ctx;

      //     ctx.restore();
      //     var fontSize = (height / 114).toFixed(2);
      //     ctx.font = fontSize + "em sans-serif";
      //     ctx.textBaseline = "middle";

      //     var text = "75%",
      //         textX = Math.round((width - ctx.measureText(text).width) / 2),
      //         textY = height / 1.7;

      //     ctx.fillText(text, textX, textY);
      //     ctx.save();
      //   }
      // });

    };

    this.ctx = this.canvas.getContext('2d');
  }

  dataChartDevices(stats: any, lang: any) {
    let data = [];

    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.busquedas.mobile_chart);
    data.push(stats.busquedas.desktop_chart);

    let chartdata = {
      labels: [labelMobile, labelDesktop],
      datasets: [{
        data: data,
        borderWidth: 0,
        backgroundColor: [
          '#1d2a41',
          '#e6798b'
        ]
      }]
    }
    return chartdata;
  }

  fillDispositivos(data: any) {
    this.stats.busquedas.mobile = data.data.devices.devices_mobile_month;
    this.stats.busquedas.desktop = data.data.devices.devices_desktop_month;
    this.stats.busquedas.mobile_percent = data.data.devices.devices_mobile_compare_last_month;
    this.stats.busquedas.desktop_percent = data.data.devices.devices_desktop_compare_last_month;
    this.stats.busquedas.mobile_chart = data.data.devices.devices_mobile_month.replaceAll('.', '');
    this.stats.busquedas.desktop_chart = data.data.devices.devices_desktop_month.replaceAll('.', '');
    // this.stats.busquedas.month = data.data.searchs.month;
    // this.stats.busquedas.month_percent = data.data.searchs.month_percent;
    // this.stats.busquedas.average_location = data.data.searchs.average_location;
    // this.stats.busquedas.month_year_last_percent = data.data.searchs.month_year_last_percent;
    // this.stats.busquedas.direct = data.data.searchs.direct;
    // this.stats.busquedas.direct_percent = data.data.searchs.direct_percent;
    // this.stats.busquedas.indirect = data.data.searchs.indirect;
    // this.stats.busquedas.indirect_percent = data.data.searchs.indirect_percent;
  };
  fillVisionados(data: any) {
    this.stats.visionados.month = data.data.views.month;
    this.stats.visionados.month_percent = data.data.views.month_percent;
    this.stats.visionados.average_location = data.data.views.average_location;
    this.stats.visionados.month_year_last_percent = data.data.views.month_year_last_percent;
    this.stats.visionados.search = data.data.views.search;
    this.stats.visionados.search_percent = data.data.views.search_percent;
    this.stats.visionados.maps = data.data.views.maps;
    this.stats.visionados.maps_percent = data.data.views.maps_percent;
  }
  fillAcciones(data: any) {
    this.stats.acciones.month = data.data.actions.month;
    this.stats.acciones.month_percent = data.data.actions.month_percent;
    this.stats.acciones.average_location = data.data.actions.average_location;
    this.stats.acciones.month_year_last_percent = data.data.actions.month_year_last_percent;
    this.stats.acciones.web = data.data.actions.web;
    this.stats.acciones.web_percent = data.data.actions.web_percent;
    this.stats.acciones.gps = data.data.actions.gps;
    this.stats.acciones.gps_percent = data.data.actions.gps_percent;
    this.stats.acciones.calls = data.data.actions.calls;
    this.stats.acciones.calls_percent = data.data.actions.calls_percent;
  }
  fillReputacion(data: any) {
    this.stats.reputacion.global.total = data.data.global_reviews.total;
    this.stats.reputacion.global.average = data.data.global_reviews.average.replaceAll(/,/g, '.');
    this.stats.reputacion.month.total = data.data.month_reviews.total;
    this.stats.reputacion.month.average = data.data.month_reviews.average.replaceAll(/,/g, '.');
  }
  fillTipologias(data: any) {
    const typologies = [];
    for (const i in data.data.typologies) {
      const newTypology = {
        name: data.data.typologies[i].name,
        global: {
          average: data.data.typologies[i].global.average,
        },
      };
      typologies.push(newTypology);
    }
    this.stats.analisis.typologies = typologies;
  }

  logOut() {
    localStorage.removeItem('access_token');
    this.router.navigate(['']);
  };
  busquedas() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['busquedas']);
    }
  };
  visionado() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['visionados']);
    }
  };
  acciones() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['acciones']);
    }
  };
  reputacion() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['reputacion']);
    }
  };
  analisis() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['analisis']);
    }
  };
  reviews() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['companies-reviews']);
    }
  }
  getStatsDefault() {
    return {
      busquedas: {
        mobile: 0,
        desktop: 0,
        mobile_percent: 0,
        desktop_percent: 0,
        chart_percent: 0,
      },
      visionados: {
        month: 0,
        month_percent: 0,
        average_location: 0,
        month_year_last_percent: 0,
        search: 0,
        search_percent: 0,
        maps: 0,
        maps_percent: 0
      },
      acciones: {
        month: 0,
        month_percent: 0,
        average_location: 0,
        month_year_last_percent: 0,
        web: 0,
        web_percent: 0,
        gps: 0,
        gps_percent: 0,
        calls: 0,
        calls_percent: 0,
      },
      reputacion: {
        global: {
          total: 0,
          total_chart: 0,
        },
        month: {
          total: 0,
          total_chart: 0,
        }
      },
      analisis: [
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
        {
          name: '',
          global: {
            average: 0,
          }
        },
      ]
    };


  }
}
