import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PanelComponent } from './components/panel/panel.component';
import { BusquedasComponent } from './components/busquedas/busquedas.component';
import { VisionadosComponent } from './components/visionados/visionados.component';
import { AccionesComponent } from './components/acciones/acciones.component';
import { ReputacionComponent } from './components/reputacion/reputacion.component';
import { AnalisisComponent } from './components/analisis/analisis.component';
import { MapComponent } from './components/map/map.component';
import { HistoricoComponent } from './components/historico/historico.component';
import { LoginTokenComponent } from './components/login-token/login-token.component';
import { NavegadorComponent } from './components/navegador/navegador.component';
import { CompaniesReviewsComponent } from './components/companies-reviews/companies-reviews.component';
import { LocationsComponent } from './components/locations/locations.component';
import { LocationEditComponent } from './components/location-edit/location-edit.component';


const routes: Routes = [
{
  path:'',
  component: LoginComponent
},
{
  path:'panel',
  component: PanelComponent
},
{
  path:'busquedas',
  component: BusquedasComponent
},
{
  path:'visionados',
  component: VisionadosComponent
},
{
  path:'acciones',
  component: AccionesComponent
},
{
  path:'reputacion',
  component: ReputacionComponent
},
{
  path:'analisis',
  component: AnalisisComponent
},
{
  path:'mapas',
  component: MapComponent
},
{
  path:'historico',
  component: HistoricoComponent
},
{
  path: 'login/:token',
  component: LoginTokenComponent
},
{
  path: 'companies-reviews',
  component: CompaniesReviewsComponent
},
{
  path: 'locations',
  component: LocationsComponent
},
{ path: 'edit-location/:codigoTienda',
  component: LocationEditComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
