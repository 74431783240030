<nav class="navbar navbar-expand-lg">

  <div class="collapse navbar-collapse">
    <img src="../assets/img/top-logo-trans.png" style="height: 50px;">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ml-2" style="margin-left: 20px;">
      
      <li class="nav-item ">
        <select class="form-control" style="width: 200px;" (change)="captureCompanySelect()">
          <option value="{{company._id}}">{{company.nombre}}</option>
        </select>
      </li>

      <li class="nav-item ">
        <select class="form-control" style="width: 200px;margin-left: 20px;" [(ngModel)]="optionBrandSelect" (change)="captureBrandSelect()" >
          <option value="0" >{{'all-brands' | translate}}</option>
          <option value="{{brand._id}}" *ngFor="let brand of brands">{{brand.nombre}}</option>
        </select>
      </li>

      <!-- Comentamos este selector para ponerlo como panel principal que llegamos desde ver panel del back de top -->
<!-- 
      <li class="nav-item ">
        <select class="form-control" style="width: 200px;margin-left: 20px;" [(ngModel)]="optionStickerSelect" (change)="captureStickerSelect()" >
          <option value="0" >{{'all-filters' | translate}}</option>
          <option value="{{sticker._id}}" *ngFor="let sticker of stickers">{{sticker.name}}</option>
        </select>
      </li> -->
      
      <li class="nav-item dropdown">
          <select  style="width: 150px;" class="form-control" [(ngModel)]="optionDateSelect" id="datePanel" (change)="captureDateSelect()" >
            <option value="{{date.value}}" [selected]="date.value === optionDateSelect" *ngFor="let date of selectDates" >{{date.name | translate}} {{date.year}}</option>
          </select>
      </li>

    </ul>
  </div>

  <div class="btn-group" [routerLink]="this.router.url">
    <button type="submit"[ngClass]="drawStats.getClassByRouter(this.router.url)" class="btn return btn-danger btn-md" (click)="returnToPanel()">{{'go-to-panel' | translate}}</button>
  </div>

  <!-- <div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="goHistory()">{{'historic' | translate}}</button>
  </div> -->

<!--  <div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="goMap()">Mapa</button>
</div> -->

  <button class="btn logOut btn-info btn-md" type="submit" [ngClass]="drawStats.getLanguageEs(activeLang)" (click)="cambiarLenguaje('es')">Spanish</button>
  <button class="btn logOut btn-info btn-md" type="submit" [ngClass]="drawStats.getLanguageEn(activeLang)" (click)="cambiarLenguaje('en')">Ingles</button>

<div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="logOut()">{{'logout' | translate}}</button>
</div>
</nav>
 