import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {BusquedasService} from 'src/app/service/busquedas.service';
@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private date = new BehaviorSubject<string>('0');
  private company = new BehaviorSubject<string>('0');
  private brand = new BehaviorSubject<string>('0');
  private sticker = new BehaviorSubject<string>('0');
  private type = new BehaviorSubject<string>('empresa');
  private lang = new BehaviorSubject<string>('es');

  public customDate = this.date.asObservable();
  public customCompany = this.company.asObservable();
  public customBrand = this.brand.asObservable();
  public customSticker = this.sticker.asObservable();
  public customType = this.type.asObservable();
  public customLang = this.lang.asObservable();

  constructor(public busquedasService: BusquedasService) {
  }

  public getDateCurrentValue(){
    return this.date.getValue();
  }

  public getCompanyCurrentValue(){
    return this.company.getValue();
  }

  public getBrandCurrentValue(){
    return this.brand.getValue();
  }
  public getStickerCurrentValue(){
    return this.sticker.getValue();
  }
  public getTypeCurrentValue(){
    return this.type.getValue();
  }
  public getLangCurrentValue(){
    return this.lang.getValue();
  }
  public changeDate(newDate: string): void {
    this.date.next(newDate);
  }

  public changeCompany(company: any): void {
		this.company.next(company);
  }

  public changeBrand(brand: any): void {
    this.brand.next(brand);
  }
  public changeSticker(sticker: any): void{
    this.sticker.next(sticker);
  }
  public changeType(type: any): void{
    this.type.next(type);
  }
  public changeLang(lang: any): void{
    this.lang.next(lang);
  }
}
