import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { BusquedasService } from 'src/app/service/busquedas.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { FilterService } from 'src/app/service/filter.service';
import { Busquedas } from './busquedas.model';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';

/* eslint-disable */

@Component({
  selector: 'app-busquedas',
  templateUrl: './busquedas.component.html',
  styleUrls: ['./busquedas.component.scss']
})

export class BusquedasComponent implements OnInit {
  canvas: any;
  newCanvas: any;
  chartUpdate: any;
  divCanvas: any;
  ctx: any;
  stats : Busquedas;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  canvasSearch: any;
  canvasMaps: any;
  newCanvasSearchs: any;
  newCanvasMaps: any;
  chartUpdateSearchs: any;
  chartUpdateMaps: any;
  divCanvasSearchs: any;
  divCanvasMaps: any;
  ctxSearch: any;
  ctxMaps: any;
  br : any
  public customBrandSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customStickerSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public busquedasService: BusquedasService,
    private drawStatsService: DrawStatsService,
    private filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
    this.drawStats = new DrawStatsService();
    this.stats = this.getStatsDefault();
  }

  ngOnInit(): void {
   this.customDateSubscription = this.filter.customDate.subscribe(date => {
    this.drawStats.addSpinner();
      const idBrand = this.filter.getBrandCurrentValue();
      const idSticker = this.filter.getStickerCurrentValue();
      if(date!=="0" && idSticker ==='0' && idBrand ==='0'){
        let newDate = new Date(date);
        let type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idBrand,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      if(date!=="0" && idBrand !=='0'){
        let newDate = new Date(date);
        let type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idBrand,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      if(date!=="0" && idSticker !=='0'){
        let newDate = new Date(date);
        let type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idSticker,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
   });
    this.customBrandSubscription = this.filter.customBrand.subscribe(brandId => {
      let date:string = this.filter.getDateCurrentValue();
      if (brandId!=="0"&& date !== "0"){
      this.drawStats.addSpinner();
      let newDate = new Date(date);
      this.filter.changeType('marca');
      let lang = this.filter.getLangCurrentValue();
      this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),brandId,"marca").subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.createChartSearch(lang);
          this.drawStats.removeSpinner();
        }else{
          this.fillBusquedas(dataStats);
          this.createChart(lang);
          this.createChartSearch(lang);
          this.drawStats.removeSpinner();
        }
      });
      }
      if (brandId === "0" && date !== "0"){
        this.drawStats.addSpinner();
      let newDate = new Date(date);
        let type = this.filter.getTypeCurrentValue();
        let lang = this.filter.getLangCurrentValue();
        this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),brandId,type).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillBusquedas(dataStats);
            this.createChart(lang);
            this.createChartSearch(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      
    });
    // this.customStickerSubscription = this.filter.customSticker.subscribe(stickerId => {
    //   let date:string = this.filter.getDateCurrentValue();
    //   if (stickerId!=="0" && date !== "0"){
    //     this.drawStats.addSpinner();
    //   let newDate = new Date(date);
    //   this.filter.changeType('sticker');
    //   let lang = this.filter.getLangCurrentValue();
    //   this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),stickerId,"sticker").subscribe((dataStats) => {
    //     if(dataStats.data === null){
    //       this.stats = this.getStatsDefault();
    //       this.createChart(lang);
    //       this.createChartSearch(lang);
    //       this.drawStats.removeSpinner();
          
    //     }else{
    //       this.fillBusquedas(dataStats);
    //       this.createChart(lang);
    //       this.createChartSearch(lang);
    //       this.drawStats.removeSpinner();
    //     }
    //   });
    //   }
    //   // if (stickerId ==="0" && date !== "0"){
    //   //   this.drawStats.addSpinner();
    //   // let newDate = new Date(date);
    //   // let type = this.filter.getTypeCurrentValue();
    //   // let lang = this.filter.getLangCurrentValue();
    //   // this.busquedasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),stickerId,type).subscribe((dataStats) => {
    //   //   if(dataStats.data === null){
    //   //     this.stats = this.getStatsDefault();
    //   //     this.createChart(lang);
    //   //     this.createChartSearch(lang);
    //   //     this.drawStats.removeSpinner();
          
    //   //   }else{
    //   //     this.fillBusquedas(dataStats);
    //   //     this.createChart(lang);
    //   //     this.createChartSearch(lang);
    //   //     this.drawStats.removeSpinner();
    //   //   }
    //   // });
    //   // }

      
    // });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{
      this.createChart(lang);
      this.createChartSearch(lang);
    });
  }
  ngOnDestroy(): void{
    this.customBrandSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    // this.customStickerSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }

  createChart(lang: any){
    this.canvas = document.getElementById('myChart');
    if(this.canvas){
      this.canvas.remove();
    }
    this.newCanvas = document.createElement("canvas");
    this.newCanvas.setAttribute("id", "myChart");
    this.newCanvas.setAttribute("width", 1020);
    this.newCanvas.setAttribute("height", 400);
    this.newCanvas.setAttribute("class", "grafic");
    this.divCanvas = document.getElementById("canvas1");
    this.divCanvas.appendChild(this.newCanvas);
    this.canvas = this.newCanvas;

    this.ctx = this.canvas.getContext('2d');

    let chartData = this.createDataChart(this.stats, lang);

    let myChart = new Chart(this.ctx, {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    });
  }

  createDataChart(stats: any, lang: any){
    
    let indirect = [];
    let direct = [];
    let month: any =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelPc = this.langMonthService.langDesktop(lang);
    for (let i in stats.historical){
      if(stats.historical[i].direct && stats.historical[i].indirect  !== "0"){
        direct.push(stats.historical[i].direct);
        indirect.push(stats.historical[i].indirect);
        month.push(this.langMonthService.langMonth(stats.historical[i].month,stats.historical[i].year, lang));
      }
    };

    let chartdata = {
      labels: month,
      datasets: [
        {
          label: labelMobile,
          data: direct,
          backgroundColor: '#1d2a41'
        },
        {
          label:labelPc,
          data: indirect,
          backgroundColor: '#e6798b'

        }
      ]
    }
    return chartdata;
  }

  createChartSearch(lang: any){
    this.canvasSearch = document.getElementById('chartSearchs');
    this.canvasMaps = document.getElementById('chartMaps');
    if(this.canvasSearch){
      this.canvasSearch.remove();
      this.newCanvasSearchs = document.createElement('canvas');
      this.newCanvasSearchs.setAttribute('id', 'chartSearchs');
      this.divCanvasSearchs = document.getElementById('canvasSearch');
      this.divCanvasSearchs.appendChild(this.newCanvasSearchs);

        this.canvas = this.newCanvasSearchs;
        this.ctxSearch = this.canvas.getContext('2d');

        let dataChartGlobal = this.dataChartSearchs(this.stats, lang);

        this.chartUpdateSearchs=  new Chart(this.ctxSearch, {
          type: 'pie',
          data: dataChartGlobal
        });

    };

    if(this.canvasMaps){
      this.canvasMaps.remove();
      this.newCanvasMaps = document.createElement("canvas");
      this.newCanvasMaps.setAttribute("id", "chartMaps");
      this.newCanvasMaps.setAttribute("class", "grafic");
      this.divCanvasMaps = document.getElementById("canvasMaps");
      this.divCanvasMaps.appendChild(this.newCanvasMaps);
     
        this.canvasMaps = this.newCanvasMaps;
        this.ctxMaps = this.canvasMaps.getContext('2d');
    
        let dataChartMaps = this.dataChartMaps(this.stats, lang);
    
        this.chartUpdateMaps=  new Chart(this.ctxMaps, {
          type: 'pie',
          data: dataChartMaps
        });
        
      
    };

    // Chart.pluginService.register({
    //   beforeDraw: function(chart) {
      
    //           if (chart.chart.ctx.canvas.id === "myChart"){
              
    //           }
    //           if(chart.chart.ctx.canvas.id === "chartSearchs"){
    //             var width = chart.chart.width,
    //             height = chart.chart.height,
    //             ctx = chart.chart.ctx;
        
    //             ctx.restore();
    //             var fontSize = (height / 114).toFixed(2);
    //             ctx.font = fontSize + "em sans-serif";
    //             ctx.textBaseline = "middle";
            
    //             var text = "20%",
    //                 textX = Math.round((width - ctx.measureText(text).width) / 2),
    //                 textY = height / 1.8;
                  
    //             ctx.fillText(text, textX, textY);
    //             ctx.save();
    //          }
    //          if(chart.chart.ctx.canvas.id=== "chartMaps"){
    //           var width = chart.chart.width,
    //           height = chart.chart.height,
    //           ctx = chart.chart.ctx;
      
    //           ctx.restore();
    //           var fontSize = (height / 114).toFixed(2);
    //           ctx.font = fontSize + "em sans-serif";
    //           ctx.textBaseline = "middle";
          
    //           var text = "80%",
    //               textX = Math.round((width - ctx.measureText(text).width) / 2),
    //               textY = height / 1.8;
                
    //           ctx.fillText(text, textX, textY);
    //           ctx.save();
    //        }
          
    //   }
    // });


  }

  dataChartSearchs(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.search_mobile_chart)
    data.push(stats.search_desktop_chart)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }

  dataChartMaps(stats:any, lang: any){
    let data =[];
    let labelMobile = this.langMonthService.langMobile(lang);
    let labelDesktop = this.langMonthService.langDesktop(lang);
    data.push(stats.maps_mobile_chart)
    data.push(stats.maps_desktop_chart)
  
      let chartdata = {
        labels: [labelMobile,labelDesktop],
        datasets:[{
          data: data,
          borderWidth: 0,
          backgroundColor: [
            '#1d2a41',
            '#e6798b'
          ]
      }]
      }
      return chartdata;
  }


  fillBusquedas(data:any){
    let historical =[] as any;
    this.stats.mobile = data.data.devices.devices_mobile_month;
    this.stats.mobile_percent = data.data.devices.devices_mobile_compare_last_month
    this.stats.desktop = data.data.devices.devices_desktop_month
    this.stats.desktop_percent = data.data.devices.devices_desktop_compare_last_month
    this.stats.maps = data.data.devices.devices_maps_month
    this.stats.search = data.data.devices.devices_searches_month
    this.stats.maps_percent = data.data.devices.views_maps_compare_last_month
    this.stats.search_percent = data.data.devices.views_searches_compare_last_month
    this.stats.search_mobile_chart = data.data.devices.devices_searches_mobile_month.replaceAll('.', '')
    this.stats.search_desktop_chart = data.data.devices.devices_searches_desktop_month.replaceAll('.', '')
    this.stats.maps_mobile_chart = data.data.devices.devices_maps_mobile_month.replaceAll('.', '')
    this.stats.maps_desktop_chart = data.data.devices.devices_maps_desktop_month.replaceAll('.', '')
    this.stats.historical = historical
      for(let i in data.data.devices.historical){
        let newHistorical={
          month: data.data.devices.historical[i].month,
          year: data.data.devices.historical[i].year,
          direct: data.data.devices.historical[i].mobile.replaceAll('.',''),
          indirect: data.data.devices.historical[i].desktop.replaceAll('.',''),
        };
        historical.push(newHistorical);
      }

  }
  getStatsDefault(){
    return {
      month: 0,
      month_percent: 0,
      average_location: 0,
      month_year_last_percent: 0,
      mobile: 0,
      mobile_percent: 0,
      desktop: 0,
      search:0,
      maps:0,
      desktop_percent: 0,
      maps_percent: 0,
      search_percent: 0,
      search_mobile_chart: 0,
      search_desktop_chart:0,
      maps_mobile_chart: 0,
      maps_desktop_chart: 0,
      historical:[
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect:0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect:0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect:0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect:0,
        },
        {
          month: 0,
          year: 0,
          mobile: 0,
          indirect:0,
        },
      ],
      table:[
        { 
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        },
        {
          ranking: 0,
          code: 0,
          label:0,
          description: "",
          total: 0,
          mobile: 0,
          indirect: 0,
        }
      ]
    }

  }

}
