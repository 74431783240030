<div class="cortina" id="cortina">
  <span class="spinner hidden" id="loaderSpinner"></span>
<app-navegador></app-navegador>

<h1 class="text-center titulo">{{'devices' | translate}}</h1>

<div class="container">
    <div class="col-md-12">
      <div class="row text-center">
          <div class="col-md-2 datosMovilPC">
            <div class="row">
              <div class="col-md-12">
                <p class="top-font-3 bold"><img class="imgIcon" src="../assets/img/ImageMobile.png" alt="">{{'mobile' | translate}}</p>
              </div>
              <div class="col-md-12 datosMovilPCPadding">
                <h3 class="top-font-1 bold">{{stats.mobile}}</h3><span [ngClass]="drawStats.getClassByNumber(stats.mobile_percent)" class="top-font-2 color-red">
                  <img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt=""> {{stats.mobile_percent}}%</span>
              </div>
              
                  <div class="col-md-12 mt-4">
                    <p class="top-font-3 bold"><img class="imgIcon" src="../assets/img/ImagePC.png" alt="">{{'desktop' | translate}}</p>
                  </div>
                  <div class="col-md-12 datosMovilPCPadding">
                    <h3 class="top-font-1 bold">{{stats.desktop}}</h3> <span [ngClass]="drawStats.getClassByNumber(stats.desktop_percent)" class="top-font-2 color-red">
                      <img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt="">  {{stats.desktop_percent}}%</span>
                  </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-5 ña">
                  <div id="canvasSearch" class="col-md chart">
                    <h2>SEARCH</h2>
                    <p class="top-font-1 bold">{{stats.search}}<span [ngClass]="drawStats.getClassByNumber(stats.search_percent)" class="top-font-2 color-red">
                      <img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt=""> {{stats.search_percent}}%</span></p>
                    <canvas id="chartSearchs"></canvas>
                  </div>
                  <div class="col-md-4 datosGraficos ">
                    <div class="row">
                        <div class="col-md-4">
                            <p>{{'mobile' | translate}}</p>
                        </div>
                        <div class="col-md-6" style="margin-right: -20px;">
                            <p class="data-mobile top-font-4">334</p>
                        </div>
                        <div class="col-md-2">
                            <p class="top-font-4">/70%</p>
                        </div>
                    </div>
                    <div class="row">
                          <div class="col-md-4">
                              <p class="top-font-reputation-4">{{'desktop' | translate}}</p>
                          </div>
                          <div class="col-md-6" style="margin-right: -20px;">
                              <p class="data-desktop  top-font-4">334</p>
                          </div>
                          <div class="col-md-2">
                              <p class="top-font-4">/70%</p>
                          </div>
                    </div>
              </div>
                  
                </div>
              <div class="col-md-5 ña">
                  <div id="canvasMaps" class="col-md chart">
                      <h2>MAPS</h2>
                      <p class="top-font-1 bold">{{stats.maps}}<span [ngClass]="drawStats.getClassByNumber(stats.maps_percent)" class="top-font-2 color-red">
                        <img class="top-font-2-img img-down" src="../assets/img/down.png" alt=""><img class="top-font-2-img img-up" src="../assets/img/up.png" alt=""> {{stats.maps_percent}}%</span></p>
                      <canvas id="chartMaps"></canvas>
                  </div>
                  <div class="col-md-4 datosGraficos">
                        <div class="row">
                            <div class="col-md-4">
                                <p>{{'mobile' | translate}}</p>
                            </div>
                            <div class="col-md-6" style="margin-right: -20px;">
                                <p class="data-mobile top-font-4">334</p>
                            </div>
                            <div class="col-md-2">
                                <p class="top-font-4">/70%</p>
                            </div>
                        </div>
                        <div class="row">
                              <div class="col-md-4">
                                  <p class="top-font-reputation-4">{{'desktop' | translate}}</p>
                              </div>
                              <div class="col-md-6" style="margin-right: -20px;">
                                  <p class="data-desktop  top-font-4">334</p>
                              </div>
                              <div class="col-md-2">
                                  <p class="top-font-4">/70%</p>
                              </div>
                        </div>
                  </div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
    <div class="container-fluid mt-5">
      <div dir="rtl" class="grafic" id="canvas1"><canvas id="myChart"></canvas></div>
    </div>
 
</div>
</div>
