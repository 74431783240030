// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const TopEnvironment = {
  production: false
};

export const topConnect ={
  apiUrl: 'https://api.toplocalmanager.com/',
  backUrl: 'https://internal.generation.toplocalmanager.com',
  histUrl: 'http://5.135.34.208:4444/',
  webUrl: '', 
  token:'jgi.-wZ)1K/oycAlLmAhR/iJ8H!e|0:VB,#SbgHw!9qVtrSNECW7A&7.T8GN=|)#u'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
